<template>
  <div class="login">
    <div class="login-box">
      <div class="input-box flex flex-direction f-around">
        <div class="flex f-center title">
          <img class="logo" src="../assets/img/logo.png" />
          <div class="ma-l-10">院前急救系统</div>
        </div>
        <div>
					<div class="flex f-center input">
					  <span class="iconfont icon-shouji"></span>
					  <van-field v-model="loginId" placeholder="请输入账号" />
					</div>
					<div class="flex f-center input">
					  <span class="iconfont icon-anquanrenzheng"></span>
					  <van-field v-model="password" type="password" placeholder="请输入密码" />
					</div>
				</div>
        
        <div class="btn" @click="submit">登 录</div>
       
      </div>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  data(){
    return {
      loginId: '',
      password: ''
    }
  },
  created (){
    _this = this;
  },
  methods:{
	// 登录
    submit(){
      if (_this.$validator.isEmpty(_this.loginId)) {
        _this.$toast.fail('请输入账号');
        return
      }
      if (_this.$validator.isEmpty(_this.password)) {
        _this.$toast.fail('请输入密码');
        return
      }

      _this.$store.dispatch('login', {LoginId: _this.loginId, Password: _this.password});
      
    },
  }
}
</script>

<style lang="less" scoped>
  .login{
    background-image: url('../assets/img/login/bg.png');
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: fixed;
    .login-box{
      width: 519px;
      height: 367px;
      background-image: url('../assets/img/login/bg01.png');
      background-size: 100% 100%;
      position: relative;
      left: 48%;
      top: 50%;
      transform: translate(-50%,-50%);
      .input-box{
        width: 269px;
        height: 305px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
        border-radius: 6px;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translate(0,-50%);
        .title{
          margin: 25px 25px 0;
          font-size: 14px;
          font-weight: bold;
          color: #1D2029;
        }
        .input{
          margin: 10px 20px;
          padding: 0 3px;
          border-bottom: 1px solid #C3C3C3;
          .iconfont{
            color: #8994A2;
          }
          .van-cell{
            padding: 10px 5px;
          }
        }
        .btn{
          width: 230px;
          height: 33px;
          background: #1A70B8;
          border-radius: 4px;
          margin: 0 auto 25px;
          text-align: center;
          line-height: 33px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .forget{
          margin: 15px 20px 0;
          a{
            color: #38A9CE;
          }
        }
      }
    }
  }
</style>

